import template from './login-register.html';

class DialogViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.providers = ko.observableArray([]);
	}

	save_click ()
	{
	}

	close_click ()
	{
		this.dialog.close(false);
	}
}

class DialogClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this);
		this.name = 'Registration';
	}

	async init ()
	{

		try {
			let res = await window.fetch('/api/auth-services', 
				{ headers: { 'Content-Type': 'application/json' } } );
			let data = await res.json();
			let providers = [];
			for (let s of data.services)
			{
				let loginRedirectURL = (s.settings.find((x) => x.name == 'loginRedirectURL'))?.value || '';
				let href = `${loginRedirectURL}?service=${s.service_uuid}`;
				providers.push({name: s.service_name, href: href});
			}
			this.viewModel.providers(providers);
		} catch (err) {
			console.error(err);
		}

	}
}

export default {
	name: 'Registration',
	dialog_class: DialogClass,
	template: template
}
