
/* Application/Library entry-point */

import caches from './caches/index.js';
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';

/*
components.forEach(function (component) {
	console.debug('Registering component', component);
	// Load all knockout components exported by the components index file
	if (!component.module_type || component.module_type == 'ko')
	{
		ko.components.register(component.name, {
			template: component.template,
			viewModel: component.viewModel
		});
	}
});
*/

// Register all grape dialogs exported by the dialogs index file
dialogs.forEach(function (dialog) {
	window.Grape.dialogs[dialog.name] = {
		pageClass: dialog.dialog_class,
		template: dialog.template
	};

});

// Register all pages exported by the pages index file
pages.forEach(function (page) {
	if (page.route)
	{
		window.Grape.route(page.route, page);
	}
	else
	{
		window.Grape.pages.add_page(page);
	}

	if (page.page_id == 'dashboard.saml2' && window.dashboard_items)
	{
		window.dashboard_items.push(page);
	}
});


//Register all grape cache entries exported by the caches index file
//caches.forEach(function (cache) {
//	Grape.cache.register(
//		cache.name,
//		cache.options
//	);
//});

