import template from './edit_saml2_idp.html';

class DialogViewModel
{
	constructor (dialog)
	{
		const self = this;
		this.dialog = dialog;

		this.error = ko.observable(null);

		this.service_uuid = ko.observable();
		this.new_service_uuid = ko.observable();
		this.entityID = ko.observable();
		this.serviceID = ko.observable();
		this.authnRequestsSigned = ko.observable();
		this.wantAssertionsSigned = ko.observable();
		this.wantMessageSigned = ko.observable();
		this.signingCertPath = ko.observable();
		this.encryptCertPath = ko.observable();
		this.assertionConsumerService = ko.observable();
		this.metadata = ko.observable();
		
		this.messageSigningOrder = ko.observable();
		this.messageSigningOrders = ko.observableArray(['sign-then-encrypt', 'encrypt-then-sign']);

		this.requestSignatureAlgorithm = ko.observable();
		this.requestSignatureAlgorithms = ko.observableArray(['http://www.w3.org/2000/09/xmldsig#rsa-sha1', 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha256', 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha512', 'http://www.w3.org/2000/09/xmldsig#hmac-sha1']);
		
		this.dataEncryptionAlgorithm = ko.observable();
		this.dataEncryptionAlgorithms = ko.observableArray(['http://www.w3.org/2001/04/xmlenc#tripledes-cbc', 'http://www.w3.org/2001/04/xmlenc#aes128-cbc', 'http://www.w3.org/2001/04/xmlenc#aes256-cbc']);

		this.keyEncryptionAlgorithm = ko.observable();
		this.keyEncryptionAlgorithms = ko.observableArray(['http://www.w3.org/2001/04/xmlenc#rsa-1_5', 'http://www.w3.org/2001/04/xmlenc#rsa-oaep-mgf1p']);

		this.allPresetVendors = ko.observableArray([
			{
				name: 'Microsoft Azure', 
				options: {
					attributeMappers: {
						givenName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
						email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
						roleNames: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups',
						displayName: 'http://schemas.microsoft.com/identity/claims/displayname',
						surname: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
					}
				},
			},
			{
				name: 'RedHat SSO', 
				options: {
					attributeMappers: {
						givenName: 'urn:oid:2.5.4.42',
						surname: 'urn:oid:2.5.4.4',
						email: 'urn:oid:1.2.840.113549.1.9.1',
						roleNames: 'Role'
					}
				}
			}
		]);
		this.selectedPresetVendor = ko.observable();
		this.selectedPresetVendorClick = function() {
			let nv = self.selectedPresetVendor();
			if (!nv || !nv.options)
				return;
			self.attributeMappers.givenName(nv.options.attributeMappers.givenName);
			self.attributeMappers.email(nv.options.attributeMappers.email);
			self.attributeMappers.roleNames(nv.options.attributeMappers.roleNames);
			self.attributeMappers.surname(nv.options.attributeMappers.surname);
			self.attributeMappers.displayName(nv.options.attributeMappers.displayName);
		};

		this.attributeMappers = {
			givenName: ko.observable('urn:oid:2.5.4.42'),
			email: ko.observable('urn:oid:1.2.840.113549.1.9.1'),
			roleNames: ko.observable('Roles'),
			surname: ko.observable('surname'),
			displayName: ko.observable('displayName')
		};

		this.allRoleNames = ko.observableArray([]);
		this.newDefaultRoleName = ko.observable();
		this.defaultRoleNames = ko.observableArray([]);

		this.roleMappers = ko.observableArray([]);
		this.newRemoteRoleName = ko.observable();
		this.newLocalRoleName = ko.observable();

		this.isAssertionEncrypted = ko.observable(false);
		this.autoCreateUsers = ko.observable(false);

		this.can_edit_metadata = ko.observable(false);

		this.IDPUsers = ko.observableArray([]);
		this.IDPUsersLoaded = ko.observable(false);

		// Tabs
		this.selected_tab = ko.observable('config');
		window.GrapeUIBS3Utils.set_active_tab('dialog_nav', this.selected_tab());
		this.selected_tab.subscribe( function(nv) {
			if (nv == 'users' && self.IDPUsersLoaded() == false)
			{
				$.getJSON('/api/saml2/idp/' + self.service_uuid() + '/users', {}, function(data) {
					if (data.users && Array.isArray(data.users))
						self.IDPUsers(data.users);
					self.IDPUsersLoaded(true);
				}).fail(function(err) {
				});
			}

			window.GrapeUIBS3Utils.set_active_tab('dialog_nav', self.selected_tab());
		});
		this.switch_tabs =  function(data, event) {
			self.selected_tab($(event.currentTarget).data('tabname'));
		};

		this.deleteRoleMapper_click = function(rm) {
			console.log('click rm', rm);
			self.deleteRoleMapper(rm);
		};
	}

	addRoleMapper ()
	{
		if (!this.newLocalRoleName() || !this.newLocalRoleName().length || this.newLocalRoleName() == '')
		{
			Grape.alert({type: 'warning', title: 'Empty input', message: 'Please select a local role name to map'});
			return;
		}
		if (!this.newRemoteRoleName() || !this.newRemoteRoleName().length || this.newRemoteRoleName() == '')
		{
			Grape.alert({type: 'warning', title: 'Empty input', message: 'Please enter a remote role name to map'});
			return;
		}

		let r = this.roleMappers();
		r.push({
			local_name: this.newLocalRoleName(),
			remote_name: this.newRemoteRoleName()
		});
		this.roleMappers(r);
		this.newLocalRoleName(null);
		this.newRemoteRoleName('');
	}

	deleteRoleMapper (rm)
	{
		let r = this.roleMappers().filter((x) => { return !(x.local_name == rm.local_name && x.remote_name == rm.remote_name) });
		this.roleMappers(r);
	}

	addDefaultRole_click ()
	{
		let role = this.newDefaultRoleName();
		if (!role || !role.length)
		{
			Grape.alert({type: 'warning', title: 'Empty input', message: 'Please select a default role name'});
			return;
		}

		let r = this.defaultRoleNames();
		if (r.indexOf(role) < 0)
			r.push(role);
		this.defaultRoleNames(r);
		this.newDefaultRoleName(null);
	}

	deleteDefaultRole_click(rm)
	{
		let r = this.defaultRoleNames().filter((x) => { return !(x == rm) });
		this.defaultRoleNames(r);
	}

	save_click ()
	{
		const self = this;

		let attributeMappers = {};
		Object.keys(this.attributeMappers).forEach(function(k) { attributeMappers[k] = self.attributeMappers[k](); });
		let roleMappers = this.roleMappers();
		
		let obj = {
			service_id: this.serviceID(),
			service_uuid: this.new_service_uuid(),
			settings: [
				{name: 'messageSigningOrder', value: this.messageSigningOrder()},
				{name: 'isAssertionEncrypted', value: this.isAssertionEncrypted()},
				{name: 'autoCreateUsers', value: this.autoCreateUsers()},
				{name: 'attributeMappers', value: attributeMappers},
				{name: 'roleMappers', value: roleMappers},
				{name: 'defaultRoles', value: this.defaultRoleNames()}
			]
		};
		$.postJSON('/grape/v2/service', obj, function( ) {
			self.dialog.close(true);
		});
	}

	close_click ()
	{
		this.dialog.close(false);
	}
}

class DialogClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this);
		this.name = 'EditSAML2IDPDialog';

		if (bindings.idp && bindings.idp.service_uuid)
		{
			this.viewModel.service_uuid(bindings.idp.service_uuid);
			this.viewModel.new_service_uuid(bindings.idp.service_uuid);
		}

	}

	async init ()
	{
		let d = await Grape.cache.fetch('AccessRoles');
		let arr = [];
		d.forEach(function(s) { if (['all', 'guest', 'any'].indexOf(s.role_name) == -1) arr.push(s.role_name); });
		this.viewModel.allRoleNames(arr);

		try {
			let data = await Grape.fetches.getJSON(`/api/saml2/idp/${this.viewModel.service_uuid()}`);
			this.viewModel.metadata(data.metadata);
			this.viewModel.serviceID(data.settings.service_id);
			this.viewModel.entityID(data.settings.entityID);
			this.viewModel.isAssertionEncrypted(data.settings.isAssertionEncrypted || false);
			this.viewModel.autoCreateUsers(data.settings.autoCreateUsers || false);
			this.viewModel.messageSigningOrder(data.settings.messageSigningOrder || 'sign-then-encrypt');
			this.viewModel.requestSignatureAlgorithm(data.settings.requestSignatureAlgorithm);
			this.viewModel.dataEncryptionAlgorithm(data.settings.dataEncryptionAlgorithm);
			this.viewModel.keyEncryptionAlgorithm(data.settings.keyEncryptionAlgorithm);
			this.viewModel.roleMappers(data.settings.roleMappers || []);
			this.viewModel.defaultRoleNames(data.settings.defaultRoles || []);

			this.viewModel.attributeMappers.givenName(data.settings.attributeMappers?.givenName || '');
			this.viewModel.attributeMappers.email(data.settings.attributeMappers?.email || '');
			this.viewModel.attributeMappers.roleNames(data.settings.attributeMappers?.roleNames || '');
			this.viewModel.attributeMappers.surname(data.settings.attributeMappers?.surname || '');
			this.viewModel.attributeMappers.displayName(data.settings.attributeMappers?.displayName || '');
		} catch (err) {
			console.error(err);
			this.viewModel.error(err.message || 'Error');
		}
	}
}

export default {
	name: 'EditSAML2IDP',
	dialog_class: DialogClass,
	template: template
}
