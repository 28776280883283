import template from './edit_saml2_service_provider.html';

class DialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.entityID = ko.observable();
		this.authnRequestsSigned = ko.observable();
		this.wantAssertionsSigned = ko.observable();
		this.wantMessageSigned = ko.observable();
		this.signingCertPath = ko.observable();
		this.encryptCertPath = ko.observable();
		this.assertionConsumerService = ko.observable();
	}

	save_click ()
	{
		let service_object = {

		};
		this.page.close({
		});
	}

	close_click ()
	{
		this.page.close(false);
	}
}

class DialogClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this);
		this.name = 'EditSAML2ServiceProviderDialog';
	}

	init ()
	{
		return new Promise(function(resolve, reject) {
			resolve();
		});
	};
}

export default {
	name: 'EditSAML2SP',
	dialog_class: DialogClass,
	template: template
}
