
// DIALOGS
import edit_sp from './edit_saml2_service_provider.js';
import edit_idp from './edit_saml2_idp.js';
import register from './login-register.js';


export default [
	edit_sp,
	edit_idp,
	register
];
