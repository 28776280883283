import template from './configure.html';

class ConfigureVM
{
	constructor (page, bindings)
	{
		this.page = page;
		this.local = {
			entityID: ko.observable(''),
			authnRequestsSigned: ko.observable(true),
			assertionConsumerService: ko.observableArray([]),
			privateKeyFile: ko.observable(''),
			wantAssertionsSigned: ko.observable(),
			wantMessageSigned: ko.observable(),
			signingCertPath: ko.observable(),
			encryptCertPath: ko.observable(),
			assertionConsumerService: ko.observable()
		};

		this.idp_metadata_url = ko.observable('');
		this.idps = ko.observableArray([]);
		
		this.loading = ko.observable(true);
		this.can_see_idps = ko.observable(false);
	}

	async loadSPServices ()
	{
		let data = await Grape.fetches.getJSON('/api/services', {type: 'SAML2 SP'});
		data.services.forEach((srv) => {
			let settings = {};
			srv.settings.forEach((s) => {
				settings[s.name] = s.value;
			});
			if (srv.service_name == 'LOCAL')
			{
				this.local.entityID(settings['entityID']);
				this.can_see_idps(true); // Only show IDPs if local is set up
			}
		});
	}

	async loadIDPServices ()
	{
		let data = await Grape.fetches.getJSON('/api/services', {type: 'SAML2 IDP'});
		let services = [];
		data.services.forEach(function(srv) {
			let settings = {};
			srv.settings.forEach(function(s) {
				settings[s.name] = s.value;
			});
			srv.settings = settings;
			services.push(srv);
		});

		this.idps(services);
	}

	async btnLocalSave_click ()
	{
		this.loading(true);
		let obj = {
			entityID: this.local.entityID()
		};
		let data = await Grape.fetches.postJSON('/api/saml2/local_service', obj);
		if (data.status == 'OK')
		{
			Grape.alert({
				type: 'success',
				message: 'Saved',
				title: 'Saved'
			});
		}
		else
		{
			Grape.alert({
				type: 'warning',
				message: 'Error while saving',
				title: 'Error'
			});
		}
		this.loading(false);
	}

	async btnAddIDP_click ()
	{
		let obj = {
			metadata_url: this.idp_metadata_url().trim()
		};
		
		if (!obj.metadata_url.length)
		{
			Grape.alert({
				type: 'warning',
				title: 'Invalid input',
				message: 'You need to enter a valid URL first'
			});
			return;
		}

		this.loading(true);
		try {
			let data = await Grape.fetches.postJSON('/api/saml2/idp', obj);
		} catch (err) {
			console.error(err);
			Grape.alert({
				type: 'danger',
				title: 'Error',
				message: err.message,
				error: err
			});
			this.loading(false);
			return;
		}
		this.idp_metadata_url('');
		Grape.alert({
			type: 'success',
			title: 'Success',
			message: 'Identity provider added'
		});
		this.loadIDPServices();
		this.loading(false);
	}

	IDPMetadataUploaded ()
	{
	}

	async btnDeleteIDP_click (idp)
	{
		if (await Grape.confirm('warning', 'Confirm delete', 'Are you sure you want to delete this identity provider?'))
		{
			$.ajax({
				url: '/api/services/' + idp.service_id,
				type: 'DELETE'
			}).done((data, textStatus, jqXHR) => {
				// Do something with the result
			}).fail((jqXHR, textStatus, errorThrown) => {
				// Handle error
			}).always(() => {
				// Request is finished
				this.loadIDPServices();
			});
		}
	}

	async btnEditIDP_click (idp)
	{
		let d = await Grape.show_dialog('EditSAML2IDP', {idp: idp});
	}
}

class ConfigurePage
{
	constructor (bindings, element)
	{
		this.viewModel = new ConfigureVM(this, bindings);
	}

	async init ()
	{
		document.title = 'Dashboard - SAML2 Configuration';

		$("div#status_container ul li").removeClass('selected_sidetab');
		$("div#status_container ul li[data-tabname='saml2']").addClass('selected_sidetab');

		this.viewModel.loading(true);
		await Promise.all([this.viewModel.loadIDPServices(), this.viewModel.loadSPServices()]);
		this.viewModel.loading(false);
	}
}

export default {
	page_class: ConfigurePage,
	template: template,
	name: 'saml2',
	title: 'SAML2',
	page_id: 'dashboard.saml2',
	icon: 'fa fa-rocket'
}
